<template>
  <v-card class="pa-0" color="transparent" flat>
    <v-row align="start">
      <v-col align-self="start">
        <span class="text-h4 font-weight-bold float-left">
          {{ title }}
        </span>
      </v-col>
      <v-col>
        <span class="float-right">
          <v-btn
            class="float-right rounded-lg"
            color="primary"
            outlined
            @click="createNew"
          >
            <v-icon left>mdi-file-plus-outline</v-icon>
            {{ $t("site.btn.createNew") }}
          </v-btn>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.mobile">
      <v-col v-for="(n, i) in items" :key="i" :cols="3">
        <v-card class="fill-width pa-2" rounded="lg" hover>
          <v-row>
            <v-col class="text-h2" cols="4" align-self="center">
              <v-avatar
                fab
                size="72"
                :color="n.backgroundColor"
                class="elevation-2"
              >
                <v-icon large class="pa-4" :color="n.color">{{
                  n.icon
                }}</v-icon>
              </v-avatar>
            </v-col>
            <v-col :cols="8" align-self="start">
              <v-container class="pa-0 fill-width">
                <v-list-item
                  dense
                  class="text-h5 font-weight-bold pa-0"
                  two-line
                >
                  <v-list-item-content class="justify-start float-start">
                    <v-list-item-subtitle
                      class="text-start no-wrap overflow-visible"
                    >
                      <strong class="text-h5 font-weight-bold">
                        {{ n.value }}
                      </strong>
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-start align-start">
                      <strong class="text-h5 font-weight-bold">
                        {{ n.count }}
                      </strong>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { TypeStageFormatter } from "@/model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";

type PropType = Record<string, string>;
type MethodType = { createNew(): void };
type DataType = Record<string, string>;
type ComputeType = Record<string, string>;

export default Vue.extend({
  name: "HeaderCardComponent",
  props: {
    title: { type: String },
    items: { type: Array, required: true } as PropValidator<
      TypeStageFormatter[]
    >,
    cols: { type: Number, default: 3 },
  },
  methods: {
    createNew() {
      this.$emit("new");
    },
  },
});
</script>

<template>
  <v-card class="transparent" flat tile>
    <newTaskComponent
      v-if="newTask"
      v-model="task"
      :today="today"
      @onClose="returnPreview"
      @onSubmit="onFormSubmit"
    />
    <v-fade-transition
      leave-absolute
      hide-on-leave
      mode="out-in"
      style="transition-delay: 0.5s"
    >
      <taskTableComponent
        v-if="!newTask"
        :items="taskList"
        :loading="loading"
        :taskStatusDesc="taskStatusDesc"
        :taskStageDesc="taskStageDesc"
        @moreItem="moreItem"
        @createNew="createNew"
      />
    </v-fade-transition>
  </v-card>
</template>

<script lang="ts">
import { TypeStageFormatter } from "@/model";
import { ROUTE_ORDERDETAILS } from "@/model/routes";
import formatterModule from "@/store/modules/formatter-module";
import {
  EnumStatus,
  EnumTaskStage,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import moment from "moment";
import Vue from "vue";
import { gotoPathQuery } from "../../helpers/component-helper";
import { i18n } from "../../plugins/i18n";
import serviceproviderModule from "../../store/modules/serviceprovider-module";
import taskModule from "../../store/modules/task-module";
import NewTaskComponent from "../subcomponent/order/newtask-component.vue";
import TaskTableComponent from "../subcomponent/order/tasktable-component.vue";

type PropType = Record<string, string>;
type MethodType = {
  createNew(): void;
  onFormSubmit(task: Task): void;
  onFormClose(): void;
  onEditTableEntry(): void;
  submitNewEntry(task: Task): Promise<Task | undefined>;
  updateFormEntry(task: Task): Promise<Task | undefined>;
  moreItem(item: Task): void;
  returnPreview(): void;
  refreshTask(): void;
};
type DataType = {
  task: Task;
  disabled: boolean;
  dialog: boolean;
  loadingMsg: string | null;
};
type ComputedType = {
  loading: boolean;
  taskList: Task[];
  serviceProviderList: ServiceProvider[];
  newTask: boolean;
  taskStageDesc: TypeStageFormatter<EnumTaskStage>[];
  today: string;
  taskStatusDesc: TypeStageFormatter<EnumStatus>[];
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "TaskComponent",
  components: {
    taskTableComponent: TaskTableComponent,
    newTaskComponent: NewTaskComponent,
  },
  data: () => ({
    task: new Task(),
    disabled: false,
    dialog: false,
    loadingMsg: null,
  }),
  computed: {
    loading: {
      get() {
        return this.$store.state.isLocalLoading;
      },
      set(value) {
        this.$store.commit("setLocalLoading", value);
      },
    },
    taskList() {
      return taskModule.taskList;
    },
    serviceProviderList() {
      return serviceproviderModule.serviceProviderList;
    },
    newTask() {
      return this.$route.query.mode == "new";
    },
    taskStageDesc() {
      return formatterModule.taskStage;
    },
    taskStatusDesc() {
      return formatterModule.taskStatus;
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
  },
  mounted() {
    serviceproviderModule.retrieveServiceProviderList();
  },
  methods: {
    createNew() {
      this.task = new Task();
      gotoPathQuery(this.$route, this.$router, {
        mode: "new",
      });
    },
    onFormSubmit() {
      const task = this.task;
      if (task) {
        if (task.id) {
          this.updateFormEntry(task).finally(() => {
            this.task = new Task();
            this.returnPreview();
          });
        } else {
          this.submitNewEntry(task).finally(() => {
            this.task = new Task();
            this.returnPreview();
          });
        }
      }
    },
    onFormClose() {
      this.task = new Task();
      this.dialog = false;
    },
    onEditTableEntry() {
      this.$router.push({
        name: ROUTE_ORDERDETAILS,
        params: {
          locale: this.$route.params.locale,
          ticketNumber: this.task.ticketNumber?.toString() ?? "",
        },
      });
    },
    submitNewEntry(task: Task) {
      this.loadingMsg = i18n.t("site.loader.loadingMsg.saving").toString();
      this.loading = true;
      this.dialog = false;
      return taskModule.createTask(task).finally(() => {
        this.loading = false;
        this.loadingMsg = null;
      });
    },
    updateFormEntry(task: Task) {
      this.dialog = false;
      this.loadingMsg = i18n.t("site.loader.loadingMsg.saving").toString();
      this.loading = true;
      return taskModule.updateTask(task).finally(() => {
        this.loading = false;
        this.loadingMsg = null;
      });
    },
    returnPreview() {
      this.task = new Task();
      this.$router.back();
    },
    moreItem(item: Task) {
      if (item && item.id) {
        this.$router.push({
          name: ROUTE_ORDERDETAILS,
          params: {
            locale: this.$route.params.locale,
            ticketNumber: item.ticketNumber,
          },
        });
      }
    },
    refreshTask() {
      if (this.task && this.task.id) {
        taskModule.retrieveTask(this.task.id);
      }
    },
  },
});
</script>

import { TypeStageFormatter } from "@/model";
import { i18n } from "@/plugins/i18n";
import {
  EnumInvoiceStage,
  EnumRoles,
  EnumStatus,
  EnumTaskStage,
  EnumUserStatus,
} from "@taskmanagement/taskapp-model";
import { colors } from "vuetify/lib";
import { Module, VuexModule, getModule } from "vuex-module-decorators";
import store from "..";

@Module({
  name: "formatter",
  store,
  dynamic: true,
})
class FormatterModule extends VuexModule {
  get taskStage(): TypeStageFormatter<EnumTaskStage>[] {
    return [
      {
        enum: EnumTaskStage.timedOut,
        value: i18n.t("object.stages.timedOut").toString(),
        color: colors.red.darken4,
      },
      {
        enum: EnumTaskStage.declined,
        value: i18n.t("object.stages.taskDeclined").toString(),
        color: colors.red.darken1,
      },
      {
        enum: EnumTaskStage.created,
        value: i18n.t("object.stages.created").toString(),
        color: colors.blue.lighten2,
      },
      {
        enum: EnumTaskStage.viewed,
        value: i18n.t("object.stages.viewed").toString(),
        color: colors.blue.darken1,
      },
      {
        enum: EnumTaskStage.accepted,
        value: i18n.t("object.stages.taskAccepted").toString(),
        color: colors.green.base,
      },
      {
        enum: EnumTaskStage.inProgress,
        value: i18n.t("object.stages.taskInProgress").toString(),
        color: colors.amber.base,
      },
      {
        enum: EnumTaskStage.done,
        value: i18n.t("object.stages.taskDone").toString(),
        color: colors.green.darken2,
      },
      {
        enum: EnumTaskStage.paymentStarted,
        value: i18n.t("object.stages.paymentStarted").toString(),
        color: colors.indigo.base,
      },
      {
        enum: EnumTaskStage.paymentComplete,
        value: i18n.t("object.stages.paymentComplete").toString(),
        color: colors.green.darken3,
      },
    ];
  }

  get taskStatus(): TypeStageFormatter<EnumStatus>[] {
    return [
      {
        enum: EnumStatus.accepted,
        value: i18n.t("object.task.orderAccepted").toString(),
        color: colors.green.darken2,
      },
      {
        enum: EnumStatus.declined,
        value: i18n.t("object.task.orderDeclined").toString(),
        color: colors.amber.darken2,
      },
      {
        enum: EnumStatus.badRequest,
        value: i18n.t("object.status.badRequest").toString(),
        color: colors.red.darken2,
      },
    ];
  }

  get invoiceStages(): TypeStageFormatter<EnumInvoiceStage>[] {
    return [
      {
        value: i18n.t("object.invoice.stage.draft").toString(),
        enum: EnumInvoiceStage.draft,
        color: colors.orange.darken2,
        backgroundColor: colors.orange.lighten3,
        icon: "fa-files-o",
      },
      {
        value: i18n.t("object.invoice.stage.saved").toString(),
        enum: EnumInvoiceStage.save,
        color: colors.cyan.darken1,
        backgroundColor: colors.cyan.lighten3,
        icon: "fa-floppy-o",
      },
      {
        value: i18n.t("object.invoice.stage.sent").toString(),
        enum: EnumInvoiceStage.send,
        color: colors.lime.darken2,
        backgroundColor: colors.lime.lighten4,
        icon: "fa-paper-plane-o",
      },
      {
        value: i18n.t("object.invoice.stage.paid").toString(),
        enum: EnumInvoiceStage.paid,
        color: colors.green.darken3,
        backgroundColor: colors.green.lighten3,
        icon: "fa-money",
      },
    ];
  }

  get userRoles(): TypeStageFormatter<EnumRoles>[] {
    return [
      {
        value: i18n.t("object.user.types.admin").toString(),
        enum: EnumRoles.ADMIN,
        color: colors.green.darken3,
        backgroundColor: colors.green.lighten3,
        icon: "mdi-account-key",
      },
      {
        value: i18n.t("object.user.types.manager").toString(),
        enum: EnumRoles.MANAGER,
        color: colors.blue.darken3,
        backgroundColor: colors.blue.lighten3,
        icon: "mdi-account-tie",
      },
      {
        value: i18n.t("object.user.types.support").toString(),
        enum: EnumRoles.SUPPORT,
        color: colors.orange.darken3,
        backgroundColor: colors.orange.lighten3,
        icon: "mdi-headset",
      },
      {
        value: i18n.t("object.user.types.agent").toString(),
        enum: EnumRoles.AGENT,
        color: colors.purple.darken3,
        backgroundColor: colors.purple.lighten3,
        icon: "fa-briefcase",
      },
      {
        value: i18n.t("object.user.types.guest").toString(),
        enum: EnumRoles.GUEST,
        color: colors.grey.darken3,
        backgroundColor: colors.grey.lighten3,
        icon: "mdi-account",
      },
    ];
  }

  get userStatus(): TypeStageFormatter<EnumUserStatus>[] {
    return [
      {
        value: i18n.t("object.user.status.online").toString(),
        enum: EnumUserStatus.ONLINE,
        color: colors.green.darken3,
        backgroundColor: colors.green.lighten3,
        icon: "mdi-check-outline",
      },
      {
        value: i18n.t("object.user.status.busy").toString(),
        enum: EnumUserStatus.BUSY,
        color: colors.orange.darken3,
        backgroundColor: colors.orange.lighten3,
        icon: "mdi-calendar-remove-outline",
      },
      {
        value: i18n.t("object.user.status.offline").toString(),
        enum: EnumUserStatus.OFFLINE,
        color: colors.red.darken3,
        backgroundColor: colors.red.lighten3,
        icon: "mdi-wifi-off",
      },
    ];
  }
}

export default getModule(FormatterModule);

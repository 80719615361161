export const ROUTE_HOME = "home";
export const ROUTE_ADMIN = "admin";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_ORDER = "order";
export const ROUTE_REQUESTER = "requester";
export const ROUTE_APPROVAL = "approval";
export const ROUTE_LOGIN = "login";
export const ROUTE_ORDERDETAILS = "orderdetail";
export const ROUTE_INVOICEDETAILS = "invoicedetail";
export const ROUTE_STATISTICS = "statistics";
export const ROUTE_INVOICE = "invoice";
export const ROUTE_WORKER_PROFILE = "serviceproviderprofile";
export const DEFAULT_ROUTE = ROUTE_HOME;

export const ROUTE_USERDETAIL = "userdetails";
export const ROUTE_USER = "user";

<template>
  <v-app>
    <router-view name="appbar" v-if="!serviceProviderView" />
    <router-view name="loader" />
    <router-view name="localloader" />
    <router-view name="systembar" />
    <router-view name="navigation" v-if="hideNavbar" />
    <router-view name="bottomnav" v-if="smallScreen && !serviceProviderView" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script lang="ts">
import { getCookies } from "@/helpers/cookies-helper";
import { ROUTE_APPROVAL, ROUTE_HOME } from "@/model/routes";
import Vue from "vue";
import { storageThemeKey } from "../plugins/vuetify";

export default Vue.extend({
  name: "App",
  mounted() {
    this.checkBrowserDarkMode();
  },
  computed: {
    hideNavbar() {
      const routeName = this.$route.name;
      const largeScreen = this.$vuetify.breakpoint.lgAndUp;
      // eslint-disable-next-line prettier/prettier
      const wrongView = (routeName === ROUTE_APPROVAL) || (routeName === ROUTE_HOME);
      const hideView = largeScreen && !wrongView;
      return hideView;
    },
    serviceProviderView() {
      return this.$route.name === ROUTE_APPROVAL;
    },
    smallScreen() {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    checkBrowserDarkMode() {
      const preferedMode = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      const storeTheme = getCookies(storageThemeKey) === "true";
      this.$vuetify.theme.dark = storeTheme ?? preferedMode;
    },
  },
});
</script>
<style>
.v-btn {
  text-transform: none;
  font-weight: 600;
  font-family: "Sofia Sans";
}
.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 0px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-weight: 600;
}
.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 48px;
}

.v-text-field--full-width .v-input__prepend-outer,
.v-text-field--full-width .v-input__prepend-inner,
.v-text-field--full-width .v-input__append-inner,
.v-text-field--full-width .v-input__append-outer,
.v-text-field--enclosed .v-input__prepend-outer,
.v-text-field--enclosed .v-input__prepend-inner,
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  align-self: center;
  justify-content: center;
  align-content: center;
}
</style>

import {
  User,
  USER_DETAIL_ROUTE,
  USER_ROUTE,
} from "@taskmanagement/taskapp-model";
import { BaseService } from "./base-service";

export class UserService extends BaseService<User> {
  constructor() {
    super(USER_ROUTE);
  }

  getByUsername(username: User["username"]): Promise<User> {
    return this.axios
      .get(USER_DETAIL_ROUTE, { params: { username } })
      .then((res) => res.data as User);
  }
}

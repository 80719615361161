<template>
  <span>
    <v-row class="fill-height">
      <v-container
        flat
        class="ma-2 fill-height"
        :max-height="$vuetify.breakpoint.mdAndUp ? 700 : null"
      >
        <v-col cols="12" md="4" class="fill-height" align-self="auto">
          <profileInfoComponent :user="user" />
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="fill-height"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <v-card class="fill-height">Hello Test</v-card>
        </v-col>
      </v-container>
    </v-row>
  </span>
</template>

<script lang="ts">
import { EnumBoolean, User } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { PropValidator } from "vue/types/options";
import ProfileInfoComponent from "../subcomponent/profile/profileinfo-component.vue";

export default Vue.extend({
  components: { profileInfoComponent: ProfileInfoComponent },
  name: "UserDetailsComponent",
  data: () => ({
    boolean: EnumBoolean,
  }),
  props: {
    user: { type: Object } as PropValidator<User>,
  },
});
</script>

<style></style>

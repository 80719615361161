<template>
  <span>
    <adminComponent />
  </span>
</template>

<script lang="ts">
import Vue from "vue";
import AdminComponent from "../subcomponent/admin/admin-component.vue";

export default Vue.extend({
  name: "AdminComponent",
  components: {
    adminComponent: AdminComponent,
  },
});
</script>

<style></style>

<template>
  <span>
    <v-card
      color="transparent"
      flat
      tile
      class="align-start justify-start rounded-lg"
    >
      <previewHeaderComponent
        class="mb-2"
        showDelete
        showEdit
        :title="$t('object.task.orderName')"
        @back="removePreview"
        @edit="editTask"
        @delete="deleteTask"
      />
      <v-card class="mb-4 align-center">
        <v-progress-linear
          v-model="progress"
          :color="warningTask ? 'error' : 'primary'"
        />
        <v-row dense>
          <v-col cols="12" md="10" align="center">
            <v-card-title>
              {{ $t("object.task.header.stage") }}
              <statusDisplayComponent
                :color="getTaskStage?.color"
                :text="getTaskStage?.value"
                class="mx-2"
              />
            </v-card-title>
          </v-col>
        </v-row>
        <v-row v-if="warningTask" dense no-gutters>
          <v-col cols="12">
            <v-banner single-line class="text-start">
              <v-icon color="error" left> mdi-information </v-icon>
              <small class="error--text justify-start align-start">
                {{ $t("object.misc.timedOutMsg") }}
              </small>
            </v-banner>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="pa-4 align-center mt-4" flat>
        <v-row>
          <v-col>
            <v-card
              :color="warningTask ? 'error' : null"
              flat
              :max-width="$vuetify.breakpoint.lgAndUp ? 600 : null"
            >
              <v-card-title class="justify-center pa-0">
                {{ $t("object.task.taskInfo") }}
              </v-card-title>
              <v-divider class="mx-6" />
              <v-card flat class="pa-2" tile>
                <v-row
                  dense
                  v-for="order in orderInformation"
                  :key="order.title"
                >
                  <v-col cols="6" align="start" v-if="order.condition ?? true">
                    {{ order.title }}
                  </v-col>
                  <v-col cols="6" align="end" v-if="order.condition ?? true">
                    <strong>{{ order.value }}</strong>
                  </v-col>
                </v-row>

                <v-card-title class="justify-center pa-0">
                  {{ $t("object.misc.clientInfo") }}
                </v-card-title>
                <v-divider />
                <v-row dense v-for="(client, i) in clientInformation" :key="i">
                  <v-col cols="6" align="start">
                    {{ client.title }}
                  </v-col>
                  <v-col cols="6" align="end">
                    <strong> {{ client.value }} </strong>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-col>
          <v-col>
            <v-card
              flat
              tile
              :max-width="$vuetify.breakpoint.lgAndUp ? 600 : null"
            >
              <v-card class="mb-2" :color="warningTask ? 'error' : null" flat>
                <v-card-title class="justify-center pa-0">
                  {{ $t("object.serviceProvider.spInfo") }}
                </v-card-title>
                <v-divider class="mx-6" />
                <v-card flat class="pa-2" tile>
                  <v-row
                    dense
                    v-for="(sp, i) in servProviderInformation"
                    :key="i"
                  >
                    <v-col cols="6" align="start">
                      {{ sp?.title }}
                    </v-col>
                    <v-col cols="6" align="start">
                      <strong> {{ sp?.value }} </strong>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
              <v-expansion-panels accordion flat>
                <v-expansion-panel v-if="task.invoice">
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-down"
                    color="primary--text"
                  >
                    {{ $t("object.invoice.invInfo") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card flat class="pa-0">
                      <v-btn
                        color="primary"
                        absolute
                        right
                        top
                        plain
                        icon
                        @click="goToInvoice(invoice)"
                      >
                        <v-icon>mdi-arrow-top-right-bold-box-outline</v-icon>
                      </v-btn>
                      <v-row
                        dense
                        v-for="(inv, i) in invoiceInformation"
                        :key="i"
                      >
                        <v-col cols="12" md="4" align="start">
                          {{ inv?.title }}
                        </v-col>
                        <v-col cols="12" md="8" align="start">
                          <strong> {{ inv?.value }} </strong>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  v-if="allocationList && allocationList.length > 0"
                >
                  <v-expansion-panel-header
                    expand-icon="mdi-menu-down"
                    color="primary--text"
                  >
                    {{ $t("object.allocation.timeline") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card flat class="pa-2">
                      <span v-for="(item, i) in allocationList" :key="item?.id">
                        <v-timeline left dense align-top>
                          <v-timeline-item
                            small
                            fill-dot
                            :color="allocationDesc(item?.stage)?.color"
                            :icon="allocationDesc(item?.stage)?.icon"
                            class="text-start"
                          >
                            <template v-slot:icon>
                              <span
                                v-if="
                                  item?.allocatedTo?.id === serviceProvider?.id
                                "
                              >
                                {{ i }}
                              </span>
                            </template>
                            <v-card max-width="300" class="px-4">
                              <v-row dense>
                                <small>
                                  {{ getFullName(item?.allocatedTo) }}
                                </small>
                              </v-row>
                              <v-row dense>
                                <small>
                                  {{ allocationDesc(item?.stage)?.value }}
                                </small>
                              </v-row>
                            </v-card>
                          </v-timeline-item>
                        </v-timeline>
                      </span>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </span>
</template>

<script lang="ts">
import {
  formatAmount,
  getFullName,
  headerEnumFormatter,
} from "@/helpers/component-helper";
import { InformationType, TypeStageFormatter } from "@/model";
import { ROUTE_INVOICEDETAILS } from "@/model/routes";
import taskModule from "@/store/modules/task-module";
import {
  Allocation,
  EnumStatus,
  EnumTaskStage,
  Invoice,
  Person,
  ServiceProvider,
  Task,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { formatTime } from "../../../helpers/component-helper";
import PreviewHeaderComponent from "../previewheader-component.vue";
import StatusDisplayComponent from "../statusdisplay-component.vue";

type ComputedType = {
  step: number;
  task: Task;
  progress: number;
  getTaskStage: TypeStageFormatter<EnumTaskStage> | null;
  getTaskStatus: TypeStageFormatter<EnumStatus> | null;
  serviceProvider: ServiceProvider;
  warningTask: boolean;
  invoice: Invoice;
  allocationList: Allocation[] | null;
  orderInformation: InformationType[];
  clientInformation: InformationType[];
  servProviderInformation: InformationType[];
  invoiceInformation: InformationType[];
};
type MethodType = {
  removePreview(): void;
  deleteTask(): void;
  editTask(): void;
  formatAmount(value: string | number): string;
  formatTime(time: string | Date): string;
  getFullName(sp: Person): string;
  allocationDesc(
    stage: EnumTaskStage
  ): TypeStageFormatter<EnumTaskStage> | null;
  goToInvoice(invoice: Invoice): void;
};
type DataType = {
  stages: typeof EnumTaskStage;
  status: typeof EnumStatus;
  spEdit: boolean;
};
type PropType = {
  taskStageDesc: Required<TypeStageFormatter<EnumTaskStage>>[];
  taskStatusDesc: Required<TypeStageFormatter<EnumStatus>>[];
  maxWidth: number;
  serviceProviderList: ServiceProvider[];
};

export default Vue.extend<DataType, MethodType, ComputedType, PropType>({
  name: "TaskPreviewComponent",
  components: {
    statusDisplayComponent: StatusDisplayComponent,
    previewHeaderComponent: PreviewHeaderComponent,
  },
  props: {
    taskStageDesc: { type: Array },
    maxWidth: { type: Number, default: 1200 },
    taskStatusDesc: { type: Array },
    serviceProviderList: { type: Array },
  },
  data: () => ({
    stages: EnumTaskStage,
    status: EnumStatus,
    spEdit: false,
  }),
  computed: {
    step() {
      return this.task?.stage;
    },
    task() {
      return taskModule.task;
    },
    progress() {
      const totalStages = this.taskStageDesc.length;
      const currentIndex = this.taskStageDesc?.findIndex(
        (stage) => stage.enum === this.step
      );
      if (currentIndex === -1) {
        return 0;
      }
      return ((currentIndex + 1) / totalStages) * 100;
    },
    getTaskStage() {
      return headerEnumFormatter(this.step, this.taskStageDesc);
    },
    getTaskStatus() {
      return headerEnumFormatter(this.task?.status, this.taskStatusDesc);
    },
    serviceProvider() {
      return this.task?.allocatedTo;
    },
    invoice() {
      return this.task.invoice ?? new Invoice();
    },
    warningTask() {
      return this.task.stage < this.stages.created;
    },
    allocationList() {
      return this.task.allocation ?? null;
    },
    orderInformation() {
      const task = this.task;
      return [
        { title: this.$i18n.t("object.task.title"), value: task?.title },
        {
          title: this.$i18n.t("object.task.header.ticketNo"),
          value: task?.ticketNumber,
        },
        {
          title: this.$i18n.t("object.task.description"),
          value: task?.description,
        },
        {
          title: this.$i18n.t("object.task.price") + "(€)",
          value: formatAmount(task?.price),
          condition: !!task?.price,
        },
        {
          title: this.$i18n.t("object.task.street"),
          value: task?.address,
        },
        {
          title: this.$i18n.t("object.person.city"),
          value: task?.city,
        },
        {
          title: this.$i18n.t("object.person.postalCode"),
          value: task?.postalCode,
        },
        {
          title: this.$i18n.t("object.task.startTime"),
          value: formatTime(task?.startTime as string),
        },
        {
          title: this.$i18n.t("object.task.endTime"),
          value: formatTime(task?.endTime as string),
        },
      ];
    },
    clientInformation() {
      const task = this.task;
      return [
        {
          title: this.$i18n.t("object.person.firstName"),
          value: task?.requesterFirstName,
        },
        {
          title: this.$i18n.t("object.person.lastName"),
          value: task?.requesterLastName,
        },
        {
          title: this.$i18n.t("object.misc.clientNo"),
          value: task?.requesterPhoneNo,
        },
      ];
    },
    servProviderInformation() {
      const sp = this.serviceProvider;
      return [
        { title: this.$i18n.t("object.misc.name"), value: getFullName(sp) },
        {
          title: this.$i18n.t("object.person.percentage"),
          value: sp?.percentage,
        },
        { title: this.$i18n.t("object.person.email"), value: sp?.email },
        {
          title: this.$i18n.t("object.person.phoneNo"),
          value: sp?.phoneNumber,
        },
      ];
    },
    invoiceInformation() {
      const inv = this.invoice;
      return [
        {
          title: this.$i18n.t("object.invoice.invNumber"),
          value: inv?.invoiceNumber,
        },
        {
          title: this.$i18n.t("object.invoice.invoiceDate"),
          value: inv?.invoiceDate,
        },
        {
          title: this.$i18n.t("object.invoice.invoiceAmount"),
          value: this.task?.invoicedAmount,
        },
      ];
    },
  },
  methods: {
    removePreview() {
      this.invoice = new Invoice();
      this.$emit("back");
    },
    deleteTask() {
      this.$emit("delete");
    },
    editTask() {
      const task = this.task;
      task.serviceProviderViewTime = undefined;
      this.$emit("edit");
    },
    formatTime(time: string) {
      return formatTime(time, undefined, this.$route.params.locale);
    },
    getFullName(sp) {
      return getFullName(sp) ?? "";
    },
    formatAmount(value: string | number) {
      return formatAmount(parseInt(value as string), this.$route.params.locale);
    },
    allocationDesc(stage: EnumTaskStage) {
      return headerEnumFormatter(stage, this.taskStageDesc);
    },
    goToInvoice(invoice: Invoice) {
      if (invoice && invoice.invoiceNumber) {
        this.$router.push({
          name: ROUTE_INVOICEDETAILS,
          params: { invoiceNumber: invoice.invoiceNumber },
        });
      }
    },
  },
});
</script>

<style></style>

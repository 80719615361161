<template>
  <span>
    <v-container>
      <headerCardComponent
        :items="userStatus"
        :title="$t('object.label.users')"
      />
    </v-container>

    <v-container>
      <statusFilterComponent :items="userRoles" @change="changeRoles" />

      <v-data-table :items="userList" :headers="headers">
        <template v-slot:[`item.status`]="{ item }">
          <statusdisplay-component
            :color="getUserStatus(item)?.color"
            :text="getUserStatus(item)?.value"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="transparent" small fab text @click="goToShowMore(item)">
            <v-icon color="primary"> mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </span>
</template>

<script lang="ts">
import { QueryParams } from "@/model";
import { ROUTE_USERDETAIL } from "@/model/routes";
import userModule from "@/store/modules/user-module";
import { EnumRoles, User } from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { DataTableHeader } from "vuetify";
import HeaderCardComponent from "../headercard-component.vue";
import StatusdisplayComponent from "../statusdisplay-component.vue";
import StatusFilterComponent from "../statusfilter-component.vue";

export default Vue.extend({
  name: "AdminComponent",
  components: {
    headerCardComponent: HeaderCardComponent,
    statusFilterComponent: StatusFilterComponent,
    StatusdisplayComponent,
  },
  computed: {
    userRoles() {
      return userModule.userRoles;
    },
    userStatus() {
      return userModule.userStatus;
    },
    userList() {
      return userModule.userList;
    },
    headers() {
      return [
        { text: "", value: "picture", width: 50, sortable: false },
        { text: this.$i18n.t("object.person.firstName"), value: "firstName" },
        { text: this.$i18n.t("object.person.lastName"), value: "lastName" },
        {
          text: this.$i18n.t("object.allocation.decision"),
          value: "status",
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ] as DataTableHeader[];
    },
  },
  methods: {
    changeRoles(role: EnumRoles) {
      this.onUserFilter({ roles: role });
    },
    onUserFilter(param?: QueryParams<User>) {
      console.log(param);
      userModule.getUserList(param);
    },
    getUserStatus(user: User) {
      return userModule.userStatus.find((x) => x.enum === user.status);
    },
    goToShowMore(user: User) {
      this.$router.push({
        name: ROUTE_USERDETAIL,
        params: { username: user.username },
      });
    },
  },
});
</script>

<style></style>

<template>
  <v-app-bar app height="100">
    <v-toolbar-title
      class="primary--text text-capitalize"
      style="font-weight: 600"
      @click="onHome"
    >
      {{ appName }}
    </v-toolbar-title>

    <v-spacer />

    <span v-if="activeUser">
      <v-btn fab text :ripple="false">
        <v-badge class="mx-1" dot overlap color="red" v-model="notification">
          <v-icon light>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>

      <appbarProfileAvatarComponent :user="activeUser" v-if="activeUser" />
    </span>

    <div class="d-flex align-center"></div>
  </v-app-bar>
</template>

<script lang="ts">
import AppbarProfileAvatarComponent from "@/components/subcomponent/appbarprofileavatar-component.vue";
import authModule from "@/store/modules/auth-module";
import Vue from "vue";
import { appName } from "../model/config";
import { ROUTE_APPROVAL, ROUTE_HOME } from "../model/routes";

export default Vue.extend({
  name: "AppBarComponent",
  components: {
    appbarProfileAvatarComponent: AppbarProfileAvatarComponent,
  },
  computed: {
    computedNavDrawer: {
      get() {
        return this.$store.state.isNavigationBarVisible;
      },
      set(val) {
        return this.$store.commit("setNavigationBarVisible", val);
      },
    },
    notification: {
      get() {
        return this.$store.state.isNotification;
      },
      set(value) {
        this.$store.commit("setIsNotification", value);
      },
    },
    appName() {
      return appName;
    },
    showNavIcon() {
      return this.$route.name != ROUTE_APPROVAL;
    },
    activeUser() {
      return authModule.activeUser;
    },
    currentRoute() {
      return this.$route;
    },

    isLargeScreen() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  methods: {
    onHome() {
      const currentRoute = this.$route.name;
      if (currentRoute === ROUTE_HOME) {
        window.location.reload();
      } else {
        this.$router.push({
          name: ROUTE_HOME,
          params: { locale: this.$route.params.locale },
        });
      }
    },
  },
});
</script>

<style></style>

<template>
  <v-card class="fill-height" flat>
    <v-img height="280">
      <v-btn absolute right icon color="primary" top="10">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
      <v-container class="fill-height flex-column align-center pa-4">
        <v-avatar size="130" color="menubg" class="primary--text text-h3 mt-2">
          <strong> MT </strong>
        </v-avatar>
        <strong class="text-h5 font-weight-bold mt-4">
          {{ fullname }}
        </strong>
        <v-card-subtitle class="pa-0">{{ userRole }}</v-card-subtitle>

        <statusdisplay-component
          :color="userStatusFormat?.color"
          :text="userStatusFormat?.value"
        />
      </v-container>
    </v-img>

    <v-card-title class="py-0">
      Contact Details
      <v-spacer></v-spacer>
      <v-btn icon color="primary">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-card-title>

    <v-divider />

    <v-list class="pa-0">
      <v-list-item v-for="(user, i) in contactDetails" :key="i">
        <v-list-item-icon>
          <v-btn fab elevation="0" v-if="user.mainIcon" :href="user.mainHref">
            <v-icon color="indigo"> {{ user.mainIcon }} </v-icon>
          </v-btn>
        </v-list-item-icon>

        <v-list-item-content class="pa-0">
          <v-list-item-title class="font-weight-bold">
            {{ user.title }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.subtitle }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn fab elevation="0" v-if="user.subIcon" :href="user.subHref">
            <v-icon>{{ user.subIcon }}</v-icon>
          </v-btn>
          <span v-else class="fill-width"></span>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { getFullName } from "@/helpers/component-helper";
import { TypeStageFormatter } from "@/model";
import formatterModule from "@/store/modules/formatter-module";
import {
  EnumBoolean,
  EnumRoles,
  EnumUserStatus,
  User,
} from "@taskmanagement/taskapp-model";
import Vue from "vue";
import { DefaultMethods } from "vue/types/options";
import statusdisplayComponent from "../statusdisplay-component.vue";

type PropType = { user: User };
type DataType = { boolean: typeof EnumBoolean };
type MethodType = DefaultMethods<unknown>;
type ComputeType = {
  userStatusFormat: TypeStageFormatter<EnumUserStatus>;
  userRole: TypeStageFormatter<EnumRoles>;
  fullname: string;
  contactDetails: {
    title: string;
    subtitle: string;
    mainIcon: string;
    subIcon: string;
    mainHref: string;
    subHref: string;
  }[];
};
export default Vue.extend<DataType, MethodType, ComputeType, PropType>({
  components: { statusdisplayComponent },
  name: "ProfileInfoComponent",
  data: () => ({
    boolean: EnumBoolean,
  }),
  props: {
    user: { type: User },
  },
  computed: {
    userStatusFormat() {
      return formatterModule.userStatus.find(
        (x) => x.enum === this.user?.status
      ) as TypeStageFormatter<EnumUserStatus>;
    },
    userRole() {
      return formatterModule.userRoles.find(
        (x) => x.enum === this.user.roles
      ) as TypeStageFormatter<EnumRoles>;
    },
    fullname() {
      return (this.user as User)?.fullName ?? getFullName(this.user) ?? "";
    },
    contactDetails() {
      const user = this.user as User;
      return [
        {
          title: user.phoneNumber ?? "",
          subtitle: "Phone Number",
          mainIcon: "mdi-phone",
          subIcon: "mdi-message-text",
          mainHref: user.phoneNumber ? `tel:${user.phoneNumber}` : "",
          subHref: user.phoneNumber ? `sms:${user.phoneNumber}` : "",
        },
        {
          title: user.email ?? "",
          subtitle: "Email",
          mainIcon: "mdi-email",
          subIcon: "",
          mainHref: user.email ? `mailto:${user.email}` : "",
          subHref: "", // No secondary action for email
        },
        {
          title: user.address ?? "",
          subtitle:
            !user.city && !user.postalCode
              ? ""
              : `${user.city}, ${user.postalCode}`,
          mainIcon: user.city && user.postalCode ? "mdi-map-marker" : "",
          subIcon: "",
          mainHref:
            user.city && user.postalCode
              ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  `${user.address}, ${user.city} ${user.postalCode}`
                )}`
              : "",
          subHref: "", // No secondary action for address
        },
      ];
    },
  },
});
</script>

<style></style>
